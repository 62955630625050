<template>
  <ib-tooltip
    popper-class="center"
    :content="$t('template.removeUneditedTemplateItems')"
    :disabled="disabled"
  >
    <component
      :is="component"
      :disabled="disabled || loading"
      placement="bottom"
      :text="$t('thisWillRemoveUneditedTemplateItems')"
      @confirm="onConfirm"
    >
      <ib-button
        class="erase-icon-button"
        variant="white"
        font-family="regular"
        :disabled="disabled"
        :loading="loading"
        :transition="false"
        @click="onClick"
      >
        <template #icon>
          <i class="ib-icon ib-icon-trash" />
        </template>
        <span class="erase-button-text">{{ $t('removeButton') }}</span>
      </ib-button>
    </component>
  </ib-tooltip>
</template>

<script>
import IbTooltipConfirm from '@/components/_v2/IbTooltipConfirm'

export default {
  name: 'EraseIconButton',

  components: {
    IbTooltipConfirm
  },

  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    confirm: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    component () {
      return this.confirm ? 'IbTooltipConfirm' : 'div'
    }
  },

  methods: {
    onClick () {
      if (this.disabled) return
      this.$emit('click')
    },

    onConfirm () {
      if (this.disabled) return
      this.$emit('confirm')
    }
  }
}
</script>

<style scoped lang="scss">
.erase-icon-button {
  ::v-deep{
    padding: 8px 15px;
    box-shadow: none;
    border-radius: 4px;
    border: solid 1px #e7e7e7;

    span {
      color: $color-red;
    }
  }

  i {
    font-size: 21px;
    padding: 2px 0;
  }
  .erase-button-text {
    display: none;

    @include media-breakpoint-up($md) {
      display: flex;
      margin-left: 5px;
    }
  }
}
</style>
